<template>
  <div class="exchangeGoods">
    <van-nav-bar
        title="门店换货"
        left-text="返回"
        left-arrow
        @click-left="onReturn"
    />
    <van-tabs type="card">
      <van-tab title="可换出商品">
        <van-list
            v-model="loading1"
            :finished="finished1"
            v-if="!isDataEmpty1"
            finished-text="没有更多了"
            @load="onLoad1"
            style="text-align: left;"
        >
          <van-card
              :price="item.price"
              :desc="item.desc"
              :title="item.commodityName"
              :thumb="item.thumb"
              v-for="(item,index) in cardList1" :key="index"
          >
            <template slot="bottom">  <!-- 自定义底部，为了实现商品数量功能 -->
              <div class="num" style="float:right;margin-top: -5px;">
                <van-field name="stepper">
                  <template #input>
                    <van-stepper v-model="item.num" integer min="0" @change="onNumChange1(item)" />
                  </template>
                </van-field>
              </div>
            </template>
          </van-card>
        </van-list>
        <van-empty description="没有数据哦" v-if="isDataEmpty1" />
      </van-tab>
      <van-tab title="可换入商品">
        <van-list
            v-model="loading2"
            :finished="finished2"
            v-if="!isDataEmpty2"
            finished-text="没有更多了"
            @load="onLoad2"
            style="text-align: left;"
        >
          <van-card
              :price="item.price"
              :desc="item.desc"
              :title="item.commodityName"
              :thumb="item.thumb"
              v-for="(item,index) in cardList2" :key="index"
          >
            <template slot="bottom">  <!-- 自定义底部，为了实现商品数量功能 -->
              <div class="num" style="float:right;margin-top: -5px;">
                <van-field name="stepper">
                  <template #input>
                    <van-stepper v-model="item.num" integer min="0" @change="onNumChange2(item)" />
                  </template>
                </van-field>
              </div>
            </template>
          </van-card>
        </van-list>
        <van-empty description="没有数据哦" v-if="isDataEmpty2" />
      </van-tab>
    </van-tabs>

    <div class="cart-submit--fixed" style="background: #FFF;width: 100%;z-index: 3001;">
      <div class="selected-order" style="width: 35%; float: left;" @click="onShowSelectedOrderList1">
        <van-icon name="cart-o" :color="showAddedList1?'#8e8efa':'rgb(100 101 103)'" />&nbsp;&nbsp;
        <span :style="showAddedList1?'vertical-align: middle;color:#8e8efa;':'vertical-align: middle;color:rgb(100 101 103);'">换出商品&nbsp;({{ selectedNum1 }})</span>
      </div>
      <div class="selected-order" style="width: 35%; float: left;" @click="onShowSelectedOrderList2">
        <van-icon name="cart-o" :color="showAddedList2?'#8e8efa':'rgb(100 101 103)'" />&nbsp;&nbsp;
        <span :style="showAddedList2?'vertical-align: middle;color:#8e8efa;':'vertical-align: middle;color:rgb(100 101 103);'">换入商品&nbsp;({{ selectedNum2 }})</span>
      </div>
      <div class="btn_order_submit" style="width: 30%; float: right;">
        <van-button type="info" :disabled="selectedNum1 == 0 || selectedNum2 == 0" @click="onAddCartSubmit" style="height: 40px;width: 100%;border: none;border-radius: 0;">确认换货</van-button>
      </div>
    </div>

    <van-popup
        class="selectedPopup"
        v-model="showAddedList1"
        closeable
        position="bottom"
        :style="{ height: 'auto' }">
      <div style="height: 55px;line-height: 55px;">已选换出商品</div>
      <van-list
          v-model="selectedLoading1"
          :finished="selectedFinished1"
          :finished-text="selectedFinishedText1"
      >
        <van-card
            :num="item.num"
            :price="item.price"
            :desc="item.desc"
            :title="item.commodityName"
            :thumb="item.thumb"
            v-for="(item,index) in addCardList1" :key="index"
        />
      </van-list>
    </van-popup>
    <van-popup
        class="selectedPopup"
        v-model="showAddedList2"
        closeable
        position="bottom"
        :style="{ height: 'auto' }">
      <div style="height: 55px;line-height: 55px;">已选换入商品</div>
      <van-list
          v-model="selectedLoading2"
          :finished="selectedFinished2"
          :finished-text="selectedFinishedText2"
      >
        <van-card
            :num="item.num"
            :price="item.price"
            :desc="item.desc"
            :title="item.commodityName"
            :thumb="item.thumb"
            v-for="(item,index) in addCardList2" :key="index"
        />
      </van-list>
    </van-popup>
  </div>
</template>

<script>
import { exchangeGoods } from "@/api/order";
import {queryCommodityListByPageForStore,queryExchangeCommodityListByPageForStore} from "@/api/commodity";
import {Toast} from "vant";

export default {
  name: "ExchangeGoods",
  data() {
    return {
      loading1: false,
      finished1: false,
      isDataEmpty1: false,
      cardList1: [],
      pageNo1: 0,
      pageSize1: 10,
      selectedNum1: 0,
      addCardList1: {},
      showAddedList1: false,
      selectedLoading1: true,
      selectedFinished1: true,
      selectedFinishedText1: '',

      loading2: false,
      finished2: false,
      isDataEmpty2: false,
      cardList2: [],
      pageNo2: 0,
      pageSize2: 10,
      selectedNum2: 0,
      addCardList2: {},
      showAddedList2: false,
      selectedLoading2: true,
      selectedFinished2: true,
      selectedFinishedText2: '',
    };
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
    },
    onLoad1() {
      // 异步更新数据
      // 列表数据
      this.pageNo1 = this.pageNo1 + 1;
      queryExchangeCommodityListByPageForStore({pageNo:this.pageNo1,pageSize:this.pageSize1}).then(response => {
        console.log(response);
        let data = response.data;
        let records = data.records;
        let current = data.current;
        let pages = data.pages;
        // console.log(records);
        // 加载状态结束
        this.loading1 = false;
        // 没有数据
        if(data.total == 0) {
          this.isDataEmpty1 = true;
          this.finished1 = true;
        } else {
          if(records) {
            for(let i in records) {
              // console.log(records[i]);
              let obj = {};
              obj.id = records[i].id;
              obj.commodityName = records[i].commodityName;
              obj.commodityCode = records[i].commodityCode;
              obj.desc = '商品编码：' + records[i].commodityCode;
              obj.price = records[i].commodityPrice;
              obj.thumb = records[i].commodityImg;
              obj.num = 0;
              this.cardList1.push(obj);
            };
          }

          // 最后一页
          if(current == pages) {
            // 数据全部加载完成
            this.finished1 = true;
          }
        }
      });
    },
    onLoad2() {
      // 异步更新数据
      // 列表数据
      this.pageNo2 = this.pageNo2 + 1;
      queryCommodityListByPageForStore({pageNo:this.pageNo2,pageSize:this.pageSize2}).then(response => {
        // console.log(response);
        let data = response.data;
        let records = data.records;
        let current = data.current;
        let pages = data.pages;
        // console.log(records);
        // 加载状态结束
        this.loading2 = false;
        // 没有数据
        if(data.total == 0) {
          this.isDataEmpty2 = true;
          this.finished2 = true;
        } else {
          if(records) {
            for(let i in records) {
              // console.log(records[i]);
              let obj = {};
              obj.id = records[i].id;
              obj.commodityName = records[i].commodityName;
              obj.commodityCode = records[i].commodityCode;
              obj.desc = '商品编码：' + records[i].commodityCode;
              obj.price = records[i].commodityPrice;
              obj.thumb = records[i].commodityImg;
              obj.num = 0;
              this.cardList2.push(obj);
            };
          }

          // 最后一页
          if(current == pages) {
            // 数据全部加载完成
            this.finished2 = true;
          }
        }
      });
    },
    onNumChange1(item) {
      // console.log(item);
      // console.log(item.num);
      if(item.num && item.num > 0) {
        this.addCardList1[item.commodityCode] = item;
      } else {
        delete this.addCardList1[item.commodityCode]
      }
      this.selectedNum1 = Object.keys(this.addCardList1).length;
    },
    onNumChange2(item) {
      // console.log(item);
      // console.log(item.num);
      if(item.num && item.num > 0) {
        this.addCardList2[item.commodityCode] = item;
      } else {
        delete this.addCardList2[item.commodityCode]
      }
      // console.log(this.addCardList2);
      this.selectedNum2 = Object.keys(this.addCardList2).length;
      // console.log(this.selectedNum2);
    },
    onAddCartSubmit() {
      if(this.selectedNum1 > 0 && this.selectedNum2 > 0) {
        // console.log(this.addCardList);
        let params = {};
        let orderType = 4;// 终端换货

        let orderPrice1 = 0;
        let replaceCommodityList = [];
        for(let code in this.addCardList1) {
          let cardItem = this.addCardList1[code];
          orderPrice1 += cardItem.price * cardItem.num;
          replaceCommodityList.push({amount: cardItem.num, commodityPrice: cardItem.price, commodityCode: cardItem.commodityCode, commodityName: cardItem.commodityName});
        }
        let orderPrice2 = 0;
        let targetCommodityList = [];
        for(let code in this.addCardList2) {
          let cardItem = this.addCardList2[code];
          // console.log(this.addCardList[code]);
          orderPrice2 += cardItem.price * cardItem.num;
          targetCommodityList.push({amount: cardItem.num, commodityPrice: cardItem.price, commodityCode: cardItem.commodityCode, commodityName: cardItem.commodityName});
        }

        if(orderPrice1 == orderPrice2) {
          params['orderType'] = orderType;
          params['orderPrice'] = orderPrice1;
          params['exchangeDetail'] = {replaceCommodityList:replaceCommodityList,targetCommodityList:targetCommodityList};
          // console.log(params)
          exchangeGoods(params).then(response => {
            console.log(response);
            this.$router.push({path: '/allOrder'});
          });
        } else {
          Toast.fail("换出与换入订单金额须一致.");
        }
      }
    },
    onShowSelectedOrderList1() {
      if(this.selectedNum1 > 0) {
        this.showAddedList2 = false;
        if(this.showAddedList1) {
          this.showAddedList1 = false;
        } else {
          let orderPrice = 0;
          for(let code in this.addCardList1) {
            let cardItem = this.addCardList1[code];
            orderPrice += cardItem.price * cardItem.num;
          }
          this.selectedFinishedText1 = '订单合计：￥'+orderPrice;
          this.showAddedList1 = true;
        }
      }
    },
    onShowSelectedOrderList2() {
      if(this.selectedNum2 > 0) {
        this.showAddedList1 = false;
        if(this.showAddedList2) {
          this.showAddedList2 = false;
        } else {
          let orderPrice = 0;
          for(let code in this.addCardList2) {
            let cardItem = this.addCardList2[code];
            orderPrice += cardItem.price * cardItem.num;
          }
          this.selectedFinishedText2 = '订单合计：￥'+orderPrice;
          this.showAddedList2 = true;
        }
      }
    },
    onReturn() {
      this.$router.go(-1);
    }
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.exchangeGoods .van-card__price {
  display: inline-block;
}
.exchangeGoods .van-card__thumb {
  width: 60px;
  height: 60px;
}
.exchangeGoods .van-card__content {
  min-height: 0;
}
.van-card {
  text-align: left;
}
.van-card__content {
  border-bottom: 1px solid #ece8e8;
  padding-bottom: 6px;
}
.van-cell {
  padding: 4px 0;
}
.van-card__price-integer {
  font-size: 18px;
}
.van-card__price-currency {
  font-size: 15px;
}
.van-card:not(:first-child) {
  margin-top: 0;
}
.cart-submit--fixed {
  position: fixed;
  bottom: 0px;
  left: 0;
  height: 40px;
  line-height: 40px;
}
.van-button::before {
  display: none;
}
.van-button__content::before {
  display: none;
}
.van-icon.van-icon-cart-o {
  font-size: 20px;
  vertical-align: middle;
}
.van-popup--bottom {
  bottom: 40px;
  max-height: 70%;
  border-bottom: 1px solid #f5f1f1;
}
</style>
